<template>
  <div >
    <div class="box1">
    <!-- 搜索框 -->
    <div class="input-box">
      品种：<el-input v-model="materialParams.mname" style="width:280px;margin-right:20px" placeholder="请输入内容"></el-input>
      <!-- 时间：<el-date-picker
      style="width:280px"
      v-model="value1"
      format="yyyy-MM-dd"
      type="datetimerange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期">
    </el-date-picker> -->
    <el-button style="margin-left:auto" icon="el-icon-refresh-left" @click="refresh">重置</el-button>
    <el-button icon="el-icon-search" class="search-btn" @click="search">搜索</el-button>
    </div>
    <!-- 分类 要是这一坨数据都一样可以封装在一起 -->
    <div class="con">
      <classify cname="市场:" v-if="firstLevelClassification.length>0" :actId="actId" :forArr="firstLevelClassification" @selelctId="selelctId1" />
      <classify cname="类别" v-if="secondLevelClassification.length>0" :act_class="'act_2'" :noIsSwitch="'false'" :actId="actId2" @selelctId="selelctId2" :forArr="secondLevelClassification" />
    </div>
    <div class="con2" v-loading="tableLoading">
        <el-table
        :data="tableData"
        style="width: 100%">
        <el-table-column
          prop="mname"
          label="品名">
        </el-table-column>
        <el-table-column
          prop="mspec"
          label="规格">
        </el-table-column>
        <el-table-column
          prop="maddr"
          label="产地">
        </el-table-column>
        <el-table-column
          prop="price"
          label="价格（元/kg)">
        </el-table-column>
        <el-table-column
          prop="trend"
          label="走势">
          <template slot-scope="scope">
            <div v-if="parseFloat(scope.row.trend * 100).toFixed(2) < 0" class="green">
              下降
            </div>
            <div v-else-if="parseFloat(scope.row.trend * 100).toFixed(2) > 0" class="red">
                上升
            </div>
            <div v-else>
                平稳
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="market"
          label="市场">
        </el-table-column>
        <el-table-column
          prop="week_range"
          label="周涨幅">
          <template slot-scope="scope">
            <!-- {{ scope.row.week_range + '%' }} -->
            <div v-if="parseFloat(scope.row.week_range * 100).toFixed(2) < 0" class="green">
              {{ parseFloat(scope.row.week_range * 100).toFixed(2) + '%'}}
            </div>
            <div v-else-if="parseFloat(scope.row.week_range * 100).toFixed(2) > 0" class="red">
              {{parseFloat(scope.row.week_range * 100).toFixed(2) + '%'}}
            </div>
            <div v-else>
              {{parseFloat(scope.row.week_range * 100).toFixed(2) + '%'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="month_range"
          label="月涨幅">
          <template slot-scope="scope">
            <!-- {{ scope.row.month_range + '%' }} -->
            <div v-if="parseFloat(scope.row.month_range * 100).toFixed(2) < 0" class="green">
              {{ parseFloat(scope.row.month_range * 100).toFixed(2) + '%'}}
            </div>
            <div v-else-if="parseFloat(scope.row.month_range * 100).toFixed(2) > 0" class="red">
              {{parseFloat(scope.row.month_range * 100).toFixed(2) + '%'}}
            </div>
            <div v-else>
              {{parseFloat(scope.row.month_range * 100).toFixed(2) + '%'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="quarter_range"
          label="季涨幅">
          <template slot-scope="scope">
            <!-- {{ scope.row.quarter_range + '%' }} -->
             <div v-if="parseFloat(scope.row.quarter_range * 100).toFixed(2) < 0" class="green">
              {{ parseFloat(scope.row.quarter_range * 100).toFixed(2) + '%'}}
            </div>
            <div v-else-if="parseFloat(scope.row.quarter_range * 100).toFixed(2) > 0" class="red">
              {{parseFloat(scope.row.quarter_range * 100).toFixed(2) + '%'}}
            </div>
            <div v-else>
              {{parseFloat(scope.row.quarter_range * 100).toFixed(2) + '%'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="data101"
          label="历史价格">
            <template slot-scope="scope">
              <div class="flex">
                <el-button type="primary" size="small" @click="toDetail(scope.row)">历史价格</el-button>
              </div>
            </template>
        </el-table-column>
        <el-table-column
          prop="data102"
          label="供货信息">
          <template slot-scope="scope">
              <div class="flex">
                <el-button type="primary" size="small" @click="gxhx(scope.row)">供货信息</el-button>
              </div>
            </template>
        </el-table-column>
    </el-table>
     <div style="text-align:right;margin:20px 0">
      <el-pagination
        background
        v-if="tableData.length>0"
        layout="prev, pager, next"
        :total="total||200"
        @current-change="currentChange"
        >
      </el-pagination>
      </div>
    </div>
    </div>
    
  </div>
</template>

<script>
import classify from './classify.vue'
export default {
  name:'priceIndex',//服务介绍-价格指数
  components: {
    classify,
  },
  data() {
    return {
      input:'',
      value1:'',
      actId:'-1',//全部
      actId2:'-1',
      actId3:'0',
      firstLevelClassification:[
        {key:'',name:'全部',id:'-1'},
        {key:'亳州',name:'亳州市场',id:'1'},
        {key:'安国',name:' 安国市场 ',id:'2'},
        {key:'成都',name:' 成都市场 ',id:'3'},
        {key:'玉林',name:' 玉林市场 ',id:'4'}],//第一级
      secondLevelClassification:[],//第二级
      tertiaryClassification:[],//第三级
      tableData:[],
      total:0,
      materialParams:{
        isChange: 0,
        isCheck: 0,
        market: "",
        mcate: "",
        mname: "",
        order: "",
        page: 1,
        pageSize: 10,
        sort: ""
      },
    }
  },
  created(){
    //请求类目的接口 第一级
    this.getFirst()
    this.getTertiary()
    this.getTableData()
  },
  methods:{
    gxhx(row){//按钮
      const { mname,mspec,id,maddr } = row
      // mname=龟甲胶&mspec=老君堂&maddr=河南
      this.$router.push({name:"SupplyDemand", params:{id:row.id}, query:{ mname,mspec,maddr }})
    },
    toDetail(row) {
      this.$router.push(`/marketDetail?id=${row.id}`)
    },
    currentChange(e){
      this.materialParams.page = e
      this.getTableData()
    },
    getFirst(){
      this.$axios.getYscPriceAllMcate().then( res => {
        this.secondLevelClassification = res.data || []
        this.secondLevelClassification.unshift({name: "全部", id: '-1'})
      })
    },
    getTertiary(){
      setTimeout(() => {
        this.tertiaryClassification = [
          {name:'日指数',id:-1},
          {name:'周指数',id:1},
          {name:'月指数',id:2},
          {name:'季指数',id:3},
          {name:'年指数',id:4},
          {name:'类目vcz33',id:5},
        ]
      }, 200);
    },
    getTableData(){
      this.tableLoading=true
      this.$axios.getYscPriceAllMaterial(this.materialParams).then( res => {
        // console.log("getYscPriceAllMaterial:",res);
        this.tableData = res.data || []
        this.total = res.total || 0
        this.tableLoading=false
      })
    },
    selelctId1(item){//第一级数据+
        // console.log('选择的',item);
        this.actId =String(item.id)
        if (item.id == '-1') {
          this.materialParams.market = ""
        }else{
          this.materialParams.market = item.key
        }
        this.materialParams.page = 1
        this.getTableData()
    },
    selelctId2(item){//第二级数据+
        // console.log('选择的',item);
        this.actId2 =String(item.id)
        if (item.id == '-1') {
          this.materialParams.market = ""
        }else{
          this.materialParams.mcate = item.id
        }
        this.materialParams.page = 1
        this.getTableData()
    },
    selelctId3(item){//第三数据+
        // console.log('选择的',item);
        this.actId3 =String(item.id)
        
    },
    search(){
      // console.log('点击了搜索');
      this.materialParams.page= 1;
      this.getTableData()
    },
    refresh(){
      this.materialParams.mname= '';
      this.search()
    },
    goRouter(){
      // console.log('点击了排行榜');
    }
  }
}
</script>
<style lang='scss' scoped>
  .input-box{
    margin:10px auto;
    width: 1152px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.06);
    border-radius: 0px;
    display:flex;
    align-items:center;
    height:77px;
    padding:0 20px
  }
  .box1{
    margin:0 auto 20px;
    width: 1152px;
  }
  .con{
    margin:20px auto;
  }
  .search-btn{
    background-color: #315CCF !important;
    color:#fff
  }
  .box1{
    min-height:1000px
  }
  .con2{
    width:100%;
  }
  .con2-box{
    width: 560px;
    height: 376px;
    margin-bottom:52px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  }
  .data-bars{
    padding:20px;
    width:calc(100% - 40px);
    height:calc(100% - 40px);
    overflow:hidden
  }
  .bars-tit{
    color: #181F2D;
    font-size:18px;
    font-weight:bold
  }
.green {
  color: green;
  font-weight: bold;
}
.red {
  color: #ff5602;
  font-weight: bold;
}
</style>