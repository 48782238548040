<template>
  <div >
    <div class="box1">
    <!-- 搜索框 -->
    <!-- <div class="input-box">
      品种：<el-input v-model="input" style="width:280px;margin-right:20px" placeholder="请输入内容"></el-input>
      时间：<el-date-picker
        style="width:280px"
        v-model="value1"
        format="yyyy-MM-dd"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
      <el-button style="margin-left:auto" icon="el-icon-refresh" @click="refresh">重置</el-button>
      <el-button icon="el-icon-search" class="search-btn" @click="search">搜索</el-button>
    </div> -->
    <!-- 分类 要是这一坨数据都一样可以封装在一起 -->
    <div class="con">
      <classify cname="市场:" v-if="firstLevelClassification.length>0" :actId="actId" :forArr="firstLevelClassification" @selelctId="selelctId1" />
      <classify cname="类别" v-if="secondLevelClassification.length>0" :act_class="'act_2'" :noIsSwitch="'false'" :actId="actId2" @selelctId="selelctId2" :forArr="secondLevelClassification" />
      <div class="filterCriteria flex">
        <div>涨落：</div>
         <div class="flex borr">
          <el-radio-group v-model="riseFallParams.isFall" size="small" @input="inputRadio1">
            <el-radio-button :label="''">全部</el-radio-button>
            <el-radio-button :label="1">涨价品种</el-radio-button>
            <el-radio-button :label="2">落价品种</el-radio-button>
          </el-radio-group>
          </div>
           <div class="flex borr list-r">
             <el-radio-group v-model="riseFallParams.timeType" size="small" @input="inputRadio2">
            <el-radio-button :label="0">日</el-radio-button>
            <el-radio-button :label="1">周</el-radio-button>
            <el-radio-button :label="2">月</el-radio-button>
            <el-radio-button :label="3">季度</el-radio-button>
            <el-radio-button :label="4">年度</el-radio-button>
          </el-radio-group>
          </div>
      </div>
    </div>
    <div class="con2" v-loading="tableLoading">
        <el-table
        :data="tableData"
        style="width: 100%">
        <el-table-column
          prop="market"
          label="市场">
        </el-table-column>
        <el-table-column
          prop="mname"
          label="品名">
        </el-table-column>
        <el-table-column
          prop="mspec"
          label="规格">
        </el-table-column>
        <el-table-column
          prop="maddr"
          label="产地">
        </el-table-column>
        <el-table-column
          prop="oldPrice"
          label="原价（元/kg)">
        </el-table-column>
        <el-table-column
          prop="currentPrice"
          label="现价（元/kg)">
        </el-table-column>
        <el-table-column
          prop="riseFall"
          label="涨叠幅">
          <template slot-scope="scoped">
            <template
              v-if="
                parseFloat(scoped.row.riseFall * 100).toFixed(2) < 0
              "
            >
              <div class="green el-icon-bottom">
                {{ parseFloat(scoped.row.riseFall * 100).toFixed(2) }}%
              </div>
            </template>
            <template
              v-else-if="
                parseFloat(scoped.row.riseFall * 100).toFixed(2) > 0
              "
            >
              <div class="red el-icon-top">
                {{ parseFloat(scoped.row.riseFall * 100).toFixed(2) }}%
              </div>
            </template>
            <template v-else>
              <div>
                {{ parseFloat(scoped.row.riseFall * 100).toFixed(2) }}%
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="data101"
          label="历史价格">
            <template slot-scope="scope">
              <div class="flex">
                <el-button type="primary" size="small" @click="gxhx(scope.row)">历史价格</el-button>
              </div>
            </template>
        </el-table-column>
    </el-table>
     <div style="text-align:right;margin:20px 0">
      <el-pagination
        background
        v-if="tableData.length>0"
        layout="prev, pager, next"
        :total="total||200"
        @current-change="currentChange"
        >
      </el-pagination>
      </div>
    </div>
    </div>
    
  </div>
</template>

<script>
import classify from './classify.vue'
export default {
  name:'priceIndex',//服务介绍-价格指数
  components: {
    classify,
  },
  data() {
    return {
      input:'',
      value1:'',
      actId:'-1',//全部
      actId2:'-1',
      actId3:'0',
      firstLevelClassification:[
        {key:'',name:'全部',id:'-1'},
        {key:'亳州',name:'亳州市场',id:'1'},
        {key:'安国',name:' 安国市场 ',id:'2'},
        {key:'成都',name:' 成都市场 ',id:'3'},
        {key:'玉林',name:' 玉林市场 ',id:'4'}],//第一级
      secondLevelClassification:[],//第二级
      tertiaryClassification:[],//第三级
      timeList:[],
      tableData:[],
      total:0,
      radio3:'全部',
      radio4:'周',
      riseFallParams:{
        isAsc: 0,
        isChange: 0,
        isFall: "",
        market: "",
        mcate: "",
        page: 1,
        pageSize: 10,
        timeType: 0
      }
    }
  },
  created(){
    //请求类目的接口 第一级
    this.getFirst()
    this.getTableData()
  },
  methods:{
    gxhx(row){//按钮
      // console.log('iterm',item);
      this.$router.push(`/marketDetail?id=${row.id}`)
    },
    currentChange(e){
      // console.log('e+>',e);
      this.riseFallParams.page = e
      this.getTableData()
    },
    getFirst(){
      this.$axios.getYscPriceAllMcate().then( res => {
        this.secondLevelClassification = res.data || []
        this.secondLevelClassification.unshift({name: "全部", id: '-1'})
      })
    },
    inputRadio1(e){
      console.log('e',e);
      this.riseFallParams.page = 1
      this.getTableData()
    },
    inputRadio2(e){
      console.log('e',e);
      this.riseFallParams.page = 1
      this.getTableData()
    },
    getTableData(){//表
      this.tableLoading=true
      this.$axios.getRiseFallData(this.riseFallParams).then( res => {
        this.tableData = res.data || []
        this.tableLoading=false
        this.total = res.total || 0
      })
    },
    selelctId1(item){//第一级数据+
      // console.log('选择的',item);
      this.actId =String(item.id)
      if (item.id == '-1') {
        this.riseFallParams.market = ""
      }else{
        this.riseFallParams.market = item.key
      }
      this.riseFallParams.page = 1
      this.getTableData()
    },
    selelctId2(item){//第二级数据+
      // console.log('选择的',item);
      this.actId2 =String(item.id)
      if (item.id == '-1') {
        this.riseFallParams.market = ""
      }else{
        this.riseFallParams.mcate = item.id
      }
      this.riseFallParams.page = 1
      this.getTableData()
    },
    search(){
      // console.log('点击了搜索');
    },
    refresh(){
      this.input= this.value1='';
      this.search()
    },
    goRouter(){
      // console.log('点击了排行榜');
    }
  }
}
</script>
<style lang='scss' scoped>
  .input-box{
    margin:10px auto;
    width: 1152px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.06);
    border-radius: 0px;
    display:flex;
    align-items:center;
    height:77px;
    padding:0 20px
  }
  .box1{
    margin:0 auto 20px;
    width: 1152px;
  }
  .con{
    margin:20px auto;
  }
  .search-btn{
    background-color: #315CCF !important;
    color:#fff
  }
  .box1{
    min-height:1000px
  }
  .con2{
    width:100%;
  }
  .con2-box{
    width: 560px;
    height: 376px;
    margin-bottom:52px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  }
  .data-bars{
    padding:20px;
    width:calc(100% - 40px);
    height:calc(100% - 40px);
    overflow:hidden
  }
  .bars-tit{
    color: #181F2D;
    font-size:18px;
    font-weight:bold
  }
  .flex {
    display:flex;
     align-items:center;
     cursor: pointer;
     .ddiv{
        height: 32px;
        line-height: 32px;
        padding:4px 8px;
        text-align: center;
        color:#000000;
        border:1px solid #eee;
     }
  }
  .list-r{
      margin-left:auto
  }
  .filterCriteria{
    margin:10px 0;
    padding:20px
  }
.green {
  color: green;
  font-weight: bold;
}
.red {
  color: #ff5602;
  font-weight: bold;
}
</style>