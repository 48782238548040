<template>
  <div >
    <div class="box1">
    <!-- 搜索框 -->
    <div class="input-box">
      品种：
      <el-select v-model="queryName" style="width:280px;margin-right:20px" placeholder="请输入内容" @change="initChart()">
        <el-option v-for="(item, index) in nameOptions" :key="index" :label="item" :value="item" ></el-option>
      </el-select>
      时间：<el-date-picker
      style="width:350px"
      v-model="queryTime"
      disabled
      format="yyyy-MM-dd"
      type="datetimerange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期">
    </el-date-picker>
    <el-button style="margin-left:auto" icon="el-icon-refresh" @click="refresh">重置</el-button>
    <el-button icon="el-icon-search" class="search-btn" @click="search">搜索</el-button>
    </div>
    <!-- 分类 -->
    <div class="con">
      <classify cname="分类:" v-if="firstLevelClassification.length>0" :actId="actId" :noIsSwitch="'false'" :forArr="firstLevelClassification" @selelctId="selelctId" />
      <classify cname="指数" v-if="secondLevelClassification.length>0" :act_class="'act_2'" :actId="zsActive" @selelctId="selelctId2" :forArr="secondLevelClassification" />
      <classify cname="类型" v-if="tertiaryClassification.length>0" :forArr="tertiaryClassification" :actId="leixinActive" @selelctId="selelctId3" />
    </div>
    <div class="con2">
      <div  class="con2-box">
        <div class="data-bars">
        <div class="bars-tit">
          大盘指数
        </div>
          <div class="bars-con">
            <div class="bars-list">
              <div :class="['list-item',{stripe:true}]">
                <div class="borr" /> 日指数:
                <div :style="`color:#FF3750`" class="item-val">
                  <template> {{dpzsMark.rzsValue}} </template>
                </div>
              </div>
              <div :class="['list-item',{stripe:false}]">
                <div class="borr" /> 日涨跌幅:
                <div :style="`color:#515A6E`" class="item-val">
                  <template> {{dpzsMark.rzfBl}}% </template>
                </div>
              </div>
              <div :class="['list-item',{stripe:true}]">
                <div class="borr" /> 周指数:
                <div :style="`color:#00C873`" class="item-val">
                  <template> {{dpzsMark.zzsValue}} </template>
                </div>
              </div>
              <div :class="['list-item',{stripe:false}]">
                <div class="borr" /> 周涨跌幅:
                <div :style="`color:#515A6E`" class="item-val">
                  <template> {{dpzsMark.zzfBl}}% </template>
                </div>
              </div>
              <div :class="['list-item',{stripe:true}]">
                <div class="borr" /> 月指数:
                <div :style="`color:#00C873`" class="item-val">
                  <template> {{dpzsMark.yzsValue}} </template>
                </div>
              </div>
              <div :class="['list-item',{stripe:false}]">
                <div class="borr" /> 月涨跌幅:
                <div :style="`color:#515A6E`" class="item-val">
                  <template> {{dpzsMark.yzfBl}}% </template>
                </div>
              </div>
              <div :class="['list-item',{stripe:true}]">
                <div class="borr" /> 季指数:
                <div :style="`color:#FF3750`" class="item-val">
                  <template> {{dpzsMark.jzsValue}} </template>
                </div>
              </div>
              <div :class="['list-item',{stripe:false}]">
                <div class="borr" /> 季涨跌幅:
                <div :style="`color:#FF3750`" class="item-val">
                  <template> {{dpzsMark.jzfBl}}% </template>
                </div>
              </div>
              <div :class="['list-item',{stripe:true}]">
                <div class="borr" /> 年指数:
                <div :style="`color:#515A6E`" class="item-val">
                  <template> {{dpzsMark.nzsValue}} </template>
                </div>
              </div>
              <div :class="['list-item',{stripe:false}]">
                <div class="borr" /> 年涨跌幅:
                <div :style="`color:#0147EB`" class="item-val">
                  <template> {{dpzsMark.nzfBl}}% </template>
                </div>
              </div>
              <div :class="['list-item',{stripe:true}]">
                <div class="borr" /> 涨落排行:
                <div :style="`color:#0147EB`" class="item-val">
                  <template> 
                    <div  style="text-decoration: underline;cursor: pointer;" @click="goRouter">
                      点击进入
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div  class="con2-box" v-loading="ChartInit1Loading">
         <myChart v-if="optionData1.yAxis" :key="myChartInit1" :red-id="`myChart1`" :option="optionData1" ref="chart1"/>
      </div>
      <div  class="con2-box" v-loading="ChartInit1Loading2">
        <myChart v-if="optionData2.yAxis" :key="myChartInit2" :red-id="`myChart2`" :option="optionData2" ref="chart2"/>
      </div>
      <div  class="con2-box" v-loading="ChartInit1Loading3" v-show="false">
        <myChart v-if="optionData3.yAxis" :key="myChartInit3" :red-id="`myChart3`" :option="optionData3" ref="chart3"/>
      </div>
    </div> 
    </div>
    
  </div>
</template>

<script>
import myChart from '@/components/myChart/index'
import classify from './classify.vue'
export default {
  name:'priceIndex',//服务介绍-价格指数
  components: {
    classify,
    myChart,
  },
  data() {
    return {
      queryName: "",
      queryTime: [],
      actId:'-1',//全部
      zsActive: "jiage",
      leixinActive: "ri",
      flActive: '',
      firstLevelClassification:[],//第一级
      secondLevelClassification:[
        {
          name: "价格指数",
          id: "jiage",
        },
        {
          name: "环比指数",
          id: "huanbi",
        },
        {
          name: "同比指数",
          id: "tongbi",
        }
      ],//第二级
      tertiaryClassification:[
        {
          name: "日指数",
          id: "ri",
          show: ["jiage"],
        },
        {
          name: "周指数",
          id: "zhou",
          show: ["jiage", "huanbi"],
        },
        {
          name: "月指数",
          id: "yue",
          show: ["jiage", "huanbi"],
        },
        {
          name: "季指数",
          id: "ji",
          show: ["jiage"],
        },
        {
          name: "年指数",
          id: "nian",
          show: ["jiage", "tongbi"],
        },
      ],//第三级
      nameOptions:[],
      myChartInit1: '111', // 图表的key
      myChartInit2: '111', // 图表的key2
      myChartInit3: '111', // 图表的key3
      optionData1: {
        title: [
          {
            top:'20',
            left:'20',
            text: '季度平均线'
          }
        ],
        grid:{
          top:'70',
          bottom:'40'
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        xAxis: {//下面的x坐标
          type: 'category',
          data: ['5.16', '6.16', '7.16', '8.16', '9.16', '10.16', '11.16', '12.16']
        },
        yAxis: {
          type: 'value'
        },
        series: [//每个x左边的y值
          {
            data: [],
            type: 'line',
            smooth: true
          }
        ]
      }, // 图标的数据1
      optionData2: {
        title: [
          {
            top:'20',
            left:'20',
            text: '年度平均线'
          }
        ],
        grid:{
          top:'70',
          bottom:'40'
        },
        xAxis: {//下面的x坐标
          type: 'category',
          data: []
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        yAxis: {
          type: 'value'
        },
        series: [//每个x左边的y值
          {
            data: [],
            type: 'line',
          }
        ]
      }, // 图标的数据2
      optionData3: {
        title: [
          {
            top:'20',
            left:'20',
            text: '***平均线'
          }
        ],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        // legend: {
        //     top:'50',
        //     left:'20',
        //     data: ['图例1', '图例2']
        // },
        grid:{
          top:'90',
          bottom:'40'
        },
        xAxis: {//下面的x坐标
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [//每个x左边的y值
          {
            name: '图例1',
            data: [],
            type: 'bar',
            itemStyle: {
              normal: {
                color: (params) => {
                  if (this.barColors[params.dataIndex] == 1) {
                    return "#5B8FF9";
                  } else if (this.barColors[params.dataIndex] == 0) {
                    return "#5AD8A6";
                  }
                },
              },
            },
          }
        ]
      }, // 图标的数据3
      barColors:[],
      ChartInit1Loading:false,//图标加载
      ChartInit1Loading2:false,//图标加载2
      ChartInit1Loading3:false,//图标加载3
      listOfIndices:[],//大盘指数的列表
      dpzsMark:{}
    }
  },
  created(){
    //请求类目的接口 第一级
    this.getFirst()
    this.initChart()
  },
  methods:{
    initZs(){
      if (this.zsActive == "jiage") {
        this.leixinActive = "ri";
      } else if (this.zsActive == "huanbi") {
        this.leixinActive = "zhou";
      } else if (this.zsActive == "tongbi") {
        this.leixinActive = "nian";
      }
    },
    initChart(){
      this.$axios.getYscStockList({
        queryName: this.queryName,
        zs: this.leixinActive,
        ds: this.zsActive,
        mcate: this.flActive
      }).then((res) => {
        // console.log("initChart:",res.data.lineDataList);
        this.dpzsMark = res.data.dpzsMark || {};
        this.optionData1.xAxis.data = res.data.lineDataList.map(function (item) {
          return item[0];
        });
        this.optionData1.series[0].data = res.data.lineDataList.map(function (item) {
          return item[1];
        });
        this.$refs.chart1.setOption(this.optionData1)

        this.optionData2.xAxis.data = res.data.yearAvgMap.yearAvgListX;
        this.optionData2.series[0].data = res.data.yearAvgMap.yearAvgListY;
        this.$refs.chart2.setOption(this.optionData2)

        this.optionData3.xAxis.data = res.data.quaAvgMap.quaAvgListX;
        this.optionData3.series[0].data = res.data.quaAvgMap.quaAvgListY;
        this.barColors = res.data.quaAvgMap.quaAvgListColor;
        this.$refs.chart3.setOption(this.optionData3)

        this.queryTime = [];
        this.queryTime.push(new Date(this.optionData1.xAxis.data[0]));
        this.queryTime.push(
          new Date(this.optionData1.xAxis.data[this.optionData1.xAxis.data.length - 1])
        );
      })
    },
    getFirst(){
      this.$axios.getYscPriceAllMcate().then( res => {
         res.data.forEach((element,index) => {
          if (index != 0 && index != 1) {
            this.firstLevelClassification.push(element)
          }
        })
        this.firstLevelClassification.unshift({name: "全部", id: '-1'})
      })
      this.$axios.getYscPmList({mcate:null}).then(res =>{
        this.nameOptions = res.data || []
      })
    },
    selelctId(item){//第一级数据+
      // console.log('选择的',item);
      if (this.actId ==String(item.id)) {
        return
      }
      this.actId =String(item.id)
      if (item.id == '-1') {
        this.flActive = ''
      }else{
        this.flActive = String(item.id)
      }
      this.queryName = '';
      this.$axios.getYscPmList({mcate:item.id}).then(res =>{
        this.nameOptions = res.data || []
      })
      this.initChart()
    },
    selelctId2(item){//第二级数据+
      // console.log('选择的',item);
      this.zsActive =String(item.id)
      this.initChart()
    },
    selelctId3(item){//第三数据+
      // console.log('选择的',item);
      this.actId3 =String(item.id)
      this.leixinActive = item.id
      this.initChart()
    },
    search(){
      // console.log('点击了搜索');
      this.initChart()
    },
    refresh(){
      this.queryName = '';
      this.search()
    },
    goRouter(){
      // console.log('点击了排行榜');
      this.$emit('clickEvent',3)
    }
  }
}
</script>
<style lang='scss' scoped>
  .input-box{
    margin:10px auto;
    width: 1152px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.06);
    border-radius: 0px;
    display:flex;
    align-items:center;
    height:77px;
    padding:0 20px
  }
  .box1{
    margin:0 auto;
    width: 1152px;
  }
  .con{
    margin:20px auto;
  }
  .search-btn{
    background-color: #315CCF !important;
    color:#fff
  }
  .box1{
    min-height:1000px
  }
  .con2{
    width:100%;
    display:flex;
    justify-content:space-between;
    flex-wrap: wrap;
  }
  .con2-box{
    width: 560px;
    height: 376px;
    margin-bottom:32px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
  }
  .data-bars{
    padding:20px;
    width:calc(100% - 40px);
    height:calc(100% - 40px);
    overflow:hidden
  }
  .bars-tit{
    color: #181F2D;
    font-size:18px;
    font-weight:bold
  }
  .bars-con{
    height:calc(100% - 35px);
    overflow-y:auto;
    margin:15px 0 15px;
    padding-right:20px;
    .borr{
      width: 4px;
      height: 4px;
      background-color: #315CCF;
      border-radius: 50%;
      margin:0 24px;
      display:inline-block;
    }
    .bars-list{
      height:auto;
      width:100%;
      display:flex;
      flex-wrap: wrap;
      .list-item{
        width:100%;
        display:flex;
        height: 34px;
        color: #181F2D;
        // line-height:34px;
        align-items: center;
      }
      .stripe{
        background-color:#e4edff
      }
      .item-val{
        margin:0 5% 0 auto ;
        text-align:left;
        width:160px
      }
    }
  }
  .bars-con::-webkit-scrollbar {
  width: 7px;
  background-color: #f1f1f1;
  }

  /* 设置滚动条滑块的样式 */
  .bars-con::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  /* 设置滚动条滑块悬停时的样式 */
  .bars-con::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  /* 设置滚动条轨道的样式 */
  .bars-con::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
</style>