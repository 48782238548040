<template>
<Wrapper>
  <div class="service">
    <DetailBanner title=""></DetailBanner>
    <water-title Chinese="价格指数" English="PRICE INDEX"></water-title>
    <div class="service-icon">
      <div v-for="(item,index) in serviceIconArr" @click="()=>{actIndex=index}" :key="index"
        :class="['icon-box',{'act':actIndex===index}]">
        <img :src="item.path" :alt="item.name" />
        <div class="text">{{item.name}}</div>
      </div>
    </div>
    <!-- 市场价格 -->
    <market v-if="actIndex===0" />
    <!-- 市场指数 -->
    <priceIndex v-if="actIndex===1" @clickEvent="clickEvent"/>
    <!-- 指数点评、 -->
    <indexReviews v-if="actIndex===2" />
    <!-- 涨落排行 -->
    <riseAndFallRanking v-if="actIndex===3" />
  </div>
  </Wrapper>
</template>

<script>
import WaterTitle from '@/components/WaterTitle.vue'
import DetailBanner from '../sections/DetailBanner.vue'
import priceIndex from './serviceComponents/priceIndex.vue' //这块的子文件都在这
import indexReviews from './serviceComponents/indexReviews.vue' //这块的子文件都在这
import market from './serviceComponents/market.vue' //这块的子文件都在这
import riseAndFallRanking from './serviceComponents/riseAndFallRanking.vue' //这块的子文件都在这
import Wrapper from '../../components/Wrapper.vue'

export default {
  mixins: [],
  components: {
    WaterTitle,
    DetailBanner,
    priceIndex,
    indexReviews,
    market,
    Wrapper,
    riseAndFallRanking
  },
  data() {
    return {
      titleAct: 'service',
      ChineseArr: {
        //中间标题变化集合
        service: {
          Chinese: '服务介绍',
          English: 'PRODUCT DISPLAY'
        },
        Corporate: {
          Chinese: '招采投标',
          English: 'Corporate Business'
        }
      },
      actIndex: 0,
      serviceIconArr: [
        //中间蓝色的那几坨
        { name: '市场价格', path: require('@/assets/imgs/service/2.png') },
        { name: '市场指数', path: require('@/assets/imgs/service/3.png') },
        { name: '指数点评', path: require('@/assets/imgs/service/4.png') },
        { name: '涨落排行', path: require('@/assets/imgs/service/1.png') }
      ]
    }
  },
  created() {},
  methods: {
    clickEvent(val){
      this.actIndex = val
    }
  }
}
</script>

<style lang='scss' scoped>
.service {
  padding: 50px 0px 0px 0px;
}
.service-icon {
  margin: 25px auto;
  display: flex;
  justify-content: center;
}
.icon-box {
  width: 228px;
  height: 156px;
  background: #ffffff;
  border: 1px solid #dbdfe9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.act {
  box-shadow: 0px 12px 30px 0px rgba(18, 55, 141, 0.2);
  border-bottom: 2px solid #0055e2;
}
.text {
  font-size: 20px;
  font-weight: bold;
  color: #515a6e;
  margin-top: 11px;
}
</style>
