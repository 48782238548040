<template>
<div>
  <div class="box">
    <div class="con" v-loading="loading">
      <div v-for="item in tableList" :key="item.id" class="list-item" >
        <div class="title">{{item.title}}  
          <img v-if="item.isHot" style="margin-left:20px" :src="require('@/assets/imgs/service/te.png')" />
            <div  style="margin-left:auto;text-decoration: underline;cursor: pointer;color:#224CDA" @click="ready(item)">
              查看详情
            </div>
        </div>

        <div class="detail">
          <img :src="item.headImg||require('@/assets/imgs/service/meng.png')" style="margin-right:10px" /> 
          <div >来源：{{item.source}}  </div>
          <div style="margin-left:20px">浏览：{{item.browse}}</div>
          <div style="margin-left:20px">时间{{item.pubDate}}</div>
           </div>
      </div>
      <div style="text-align:right">
      <el-pagination
        background
        v-if="tableList.length>0"
        layout="prev, pager, next"
        :total="total"
        @current-change="currentChange"
        >
      </el-pagination>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
export default {
  name:'indexReviews',
  data() {
    return {
      tableList:[],
      total:100,
      loading:false,
      yshNewsParams:{
        "newstype":10,
        "page":1,
        "limit":20
      }
    }
  },
  created(){
    this.getList()
  },
  methods:{
    getList(){
      this.loading= true
      this.$axios.getYshNews2List(this.yshNewsParams).then( res => {
        this.total = res.data.totalElements || 0
        this.tableList = res.data.content || []
        this.loading= false
      })
    },
    ready(item){
      // console.log('item+>',item);
      this.$router.push('/serviceArticle/'+item.id)
    },
    currentChange(e){
      console.log('e+>',e);
      this.getList()
    }
  }
}
</script>
<style lang='scss' scoped>
.box{
  margin:30px auto;
    width: 1152px;
    min-height:500px
}
.list-item{
  height:75px;
  border-bottom: 1px solid #DCDFE8;
  margin-bottom:20px;
  color: #98A4BF;
  .title{
    font-size: 16px;
    color:#000000;
    font-weight:bold;
    margin-bottom:15px

  }
  .detail ,.title{
    display:flex;
    align-items:center;
  }
}
</style>